<template lang="pug">
.login(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  .container-fluid
    v-row.pa-0.ma-0(style="height: 100vh")
      v-col.pa-0.main_bg.d-none.d-md-flex.align-center.justify-center(
        cols="12",
        md="6"
      )
        .description
          h1 尚未註冊為會員？
          button.btn.sign_up_now(@click="toRegister") 立即註冊
      v-col.d-flex.pa-0.opacity_bg(cols="12", md="6", style="width: 100%")
        v-container.d-flex.align-center.justify-center.w-100
          v-row.justify-center(style="flex-shrink: 1; flex-grow: 1")
            v-col.d-flex.justify-center.align-center(cols="12")
              img.logo(:src="logo", target="_blank")
              h1.text-center.login-title 會員登入
            v-col(cols="8")
              form.form-login(style="margin-top: 40px")
                .input_container
                  label.sr-only(for="id_username") 會員帳號
                  input#id_username(
                    required,
                    type="email",
                    name="username",
                    placeholder="帳號為Email或電話號碼",
                    autofocus="",
                    v-model="username"
                  )
                  svg-icon(iconClass="user_hollow", className="user_hollow")
                .input_container.mt-6
                  label.sr-only(for="id_password") 會員密碼
                  input#id_password(
                    required,
                    type="password",
                    name="password",
                    placeholder="設定會員密碼",
                    v-model="password"
                  )
                  svg-icon(iconClass="key_hollow", className="key_hollow")
                span.fgp_btn(
                  style="cursor: pointer",
                  @click="toForgetPassword"
                ) 忘記密碼？
                .btn.btn.login.w-100(
                  style="margin-top: 36px",
                  @click="loginBtnClick"
                ) 登入
                .or.text-center
                  span OR
                .btn.btn.sign_up(@click="toRegister") 前往註冊
            v-col.d-flex.justify-center.align-center(cols="12")
              v-footer(align="center")
                .footer
                  h5 Copyright © 2022 競晰股份有限公司
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import Logo from "@/assets/img/sign_up/logo.png";
import { mapActions } from "vuex";
import { validStringEmpty } from "@/utils/validate";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";

export default {
  name: "Login",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      logo: Logo,

      username: "",
      password: "",

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "會員登入 | Game Changer",
        description: "會員登入",
        keywords: "Game changer,籃球,會員,會員登入",
        url: "https://www.gamechanger.tw/login",
        image: "",
        smoType: "website",
      },
    };
  },
  methods: {
    ...mapActions("user", ["login"]),
    loginBtnClick() {
      if (validStringEmpty(this.username) || validStringEmpty(this.password)) {
        this.showErrorDialog("錯誤", "請輸入帳號密碼");
        return;
      }
      this.showLoadingDialog("登入中");
      this.login({ username: this.username, password: this.password })
        .then(() => {
          this.closeLoadingDialog();
          this.$router.push({ name: "Home" });
        })
        .catch(() => {
          this.closeLoadingDialog();
          this.showErrorDialog("登入失敗", "請確認帳號密碼");
        });
    },
    toRegister() {
      this.$router.push({ name: "Register" });
    },
    toForgetPassword() {
      this.$router.push({ name: "ForgetPassword" });
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/user/login";
</style>
